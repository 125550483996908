import React, { useEffect, useState } from "react";
import { Button, Title, Select } from "rbx";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { GET_REVIEWS } from "./graphql";

import { Loader } from "../../components";
import IncompleteReviews from "./IncompleteReviews";
import CompletedReviews from "./CompletedReviews";
import "./Reviews.scss";

const Reviews = ({ employeeNumber }) => {
  // Year Select Values

  const [filters, setFilters] = useState({
    year: String(new Date().getFullYear()),
  });
  const goLiveYear = 2023;
  const currentYearPlusOne = new Date().getFullYear() + 1;
  const range = (start, stop, step) =>
    Array.from({ length: (stop - start) / step + 1 }, (_, i) =>
      String(start + i * step)
    );
  const uniqueYearOptions = range(goLiveYear, currentYearPlusOne, 1);

  const handleFilterChange = (name, val) => {
    setFilters({ ...filters, [name]: val });
  };
  const [reviews, setReviews] = useState([]);
  const [isCompletedReviews, setIsCompletedReviews] = useState(false);
  // brings back all reviews where at least one authorEmployeeNumber matches their employee number
  const {
    data: ReviewData,
    loading,
    refetch,
  } = useQuery(GET_REVIEWS, {
    variables: {
      where: {
        status: { equals: isCompletedReviews ? "COMPLETED" : "INCOMPLETE" },
        dateToCompleteBy: { startsWith: filters?.year },
        evaluations: {
          some: {
            authorEmployeeNumber: {
              contains: employeeNumber,
            },
          },
        },
      },
      // orderBy: [
      //   {
      //     name: "asc",
      //   },
      // ],
      // sectionsOrderBy: [{ order: "asc" }],
      // questionsOrderBy: [{ order: "asc" }],
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (typeof ReviewData === "object") {
      setReviews(ReviewData?.reviews);
    }
  }, [ReviewData, refetch]);

  if (loading) {
    return (
      <div className="inner-container">
        <Loader />
      </div>
    );
  }

  if (!reviews.length) {
    return (
      <div className="container">
        <div className="select-container-top-right">
          <Select.Container sx={{ marginLeft: "1rem" }}>
            <Select
              style={{ width: "10rem" }}
              value={filters?.year}
              onChange={(e) => handleFilterChange("year", e?.target?.value)}
            >
              {uniqueYearOptions.map((year) => (
                <Select.Option key={year} value={year}>
                  {year}
                </Select.Option>
              ))}
            </Select>
          </Select.Container>
        </div>
        <div className="inner-container-center">
          <Button
            className={isCompletedReviews ? "unselectedBtn" : "selectedBtn"}
            type="button"
            onClick={() => setIsCompletedReviews(!isCompletedReviews)}
          >
            <p>Incomplete</p>
          </Button>
          <Button
            className={isCompletedReviews ? "selectedBtn" : "unselectedBtn"}
            type="button"
            onClick={() => setIsCompletedReviews(!isCompletedReviews)}
          >
            <p>Completed</p>
          </Button>
        </div>
        <div className="inner-container-center">
          <div className="reviews" style={{ textAlign: "center" }}>
            <Title>No Reviews Found For Selected Year And Status</Title>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="select-container-top-right">
        <Select.Container sx={{ marginLeft: "1rem" }}>
          <Select
            style={{ width: "10rem" }}
            value={filters?.year}
            onChange={(e) => handleFilterChange("year", e?.target?.value)}
          >
            {uniqueYearOptions.map((year) => (
              <Select.Option key={year} value={year}>
                {year}
              </Select.Option>
            ))}
          </Select>
        </Select.Container>
      </div>
      <div className="inner-container-center">
        <Button
          className={isCompletedReviews ? "unselectedBtn" : "selectedBtn"}
          type="button"
          onClick={() => setIsCompletedReviews(!isCompletedReviews)}
        >
          <p>Incomplete</p>
        </Button>
        <Button
          className={isCompletedReviews ? "selectedBtn" : "unselectedBtn"}
          type="button"
          onClick={() => setIsCompletedReviews(!isCompletedReviews)}
        >
          <p>Completed</p>
        </Button>
      </div>
      {isCompletedReviews ? (
        <CompletedReviews
          employeeNumber={employeeNumber}
          loading={loading}
          reviews={reviews}
          setReviews={setReviews}
        />
      ) : (
        <IncompleteReviews
          employeeNumber={employeeNumber}
          loading={loading}
          refetchMyReviews={refetch}
          reviews={reviews}
          setReviews={setReviews}
        />
      )}
    </div>
  );
};

Reviews.propTypes = {
  employeeNumber: PropTypes.string.isRequired,
};

export default Reviews;
