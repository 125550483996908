import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Icon, Title } from "rbx";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReviewCycleForm from "../ReviewCycleForm/ReviewCycleForm";
import { INITIAL_CYCLE_INPUTS } from "../../constants";
import ReviewSummaryList from "../../../ReviewSummaryList/ReviewSummaryList";
import {
  GET_REVIEW_TEMPLATES,
  GET_ALL_REVIEW_CYCLES,
  CREATE_REVIEW_CYCLE,
  DELETE_REVIEW_CYCLE,
  UPDATE_REVIEW_CYCLE,
} from "../../graphql";
import Details from "../../../Details";
import "./ReviewCycleModal.scss";
import { useModal } from "../../../../context";
import { Confirmation } from "../../../../components";
import checkMobile from "../../../../utils/checkForMobile";

const ReviewCycleModal = ({
  onComplete,
  readOnly,
  reviewCycle,
  refetchReviewCycleList,
}) => {
  const [inputs, setInputs] = useState(
    readOnly ? reviewCycle : INITIAL_CYCLE_INPUTS
  );
  const { setModalOpen } = useModal();
  const [allReviews, setAllReviews] = useState([]);
  const [isEditing, setIsEditing] = useState(!readOnly);

  const [selectedReview, setSelectedReview] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [selectedEvalId, setSelectedEvalId] = useState("");

  const isDisabled =
    !inputs.templateId ||
    !inputs.name ||
    !inputs.startDate ||
    !inputs.groupCycle;
  const title = `${!readOnly ? "Create New" : ""} Review Cycle`;

  const [createReviewCycle] = useMutation(CREATE_REVIEW_CYCLE);
  const [updateReviewCycle] = useMutation(UPDATE_REVIEW_CYCLE);
  const [deleteReviewCycle] = useMutation(DELETE_REVIEW_CYCLE);
  const isMobile = checkMobile();

  // review Templates not reviews
  const { data: ReviewTemplatesData } = useQuery(GET_REVIEW_TEMPLATES, {
    variables: {},
  });

  useEffect(() => {
    if (ReviewTemplatesData) {
      setAllReviews(ReviewTemplatesData?.reviewTemplates);
    }
  }, [ReviewTemplatesData]);

  useEffect(() => {
    if (inputs.templateId) {
      const selected = allReviews?.filter(
        (review) => review?.id === inputs.templateId
      );

      const selectedFormatted = {
        ...selected[0],
        evaluations: selected?.[0]?.evaluationTemplates,
      };

      delete selectedFormatted.evaluationTemplates;

      setSelectedReview(selectedFormatted);
    }
  }, [inputs.templateId, allReviews]);

  const handleShowDetails = (id) => {
    setSelectedEvalId(id);
    setShowDetails(!showDetails);
  };

  const handleInputChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditing && readOnly) {
        const UpdateReviewCycleInputs = {
          groupCycle: {
            set: inputs.groupCycle,
          },
          name: {
            set: inputs.name,
          },
          startDate: {
            set: inputs.startDate,
          },
          templateId: {
            set: inputs.templateId,
          },
        };

        await updateReviewCycle({
          variables: {
            where: {
              id: inputs.id,
            },
            data: {
              ...UpdateReviewCycleInputs,
            },
          },
          refetchQueries: [
            {
              query: GET_ALL_REVIEW_CYCLES,
              fetchPolicy: "network-only",
            },
          ],
        });
        toast.success("Review Cycle Updated");
      } else {
        const CreateReviewCycleInputs = {
          groupCycle: inputs.groupCycle,
          name: inputs.name,
          startDate: inputs.startDate,
          templateId: inputs.templateId,
          createdAt: new Date().toISOString(),
        };

        await createReviewCycle({
          variables: {
            data: {
              ...CreateReviewCycleInputs,
            },
          },
          refetchQueries: [
            {
              query: GET_ALL_REVIEW_CYCLES,
              fetchPolicy: "network-only",
            },
          ],
        });
        toast.success("Review Cycle Created");
      }
      refetchReviewCycleList();
      onComplete();
    } catch (err) {
      toast.error("An error has occured, please try again later");
    }
  };

  const handleDeleteReviewCycle = async () => {
    try {
      await deleteReviewCycle({
        variables: {
          where: {
            id: inputs.id,
          },
        },
        refetchQueries: [
          {
            query: GET_ALL_REVIEW_CYCLES,
            fetchPolicy: "network-only",
          },
        ],
      });
      onComplete();
      toast.success("Review Cycle Deleted");
    } catch (err) {
      toast.error("An error has occured, please try again later");
    }
  };

  const handleConfirm = () => {
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to delete this review cycle?"
        onCancel={() => setModalOpen(false)}
        onConfirm={handleDeleteReviewCycle}
      />
    );
  };

  if (showDetails) {
    return (
      <div className="show-details-container">
        <Button
          className="back-btn"
          type="button"
          onClick={() => setShowDetails(false)}
        >
          <Icon className="arrow-icon">
            <FontAwesomeIcon icon="arrow-left" />
          </Icon>
          <p>Back</p>
        </Button>
        <Details
          isModalView
          evaluationId={selectedEvalId}
          keyType="evaluation-template"
        />
      </div>
    );
  }

  return (
    <form
      id={isMobile ? "review-cycle-form-mobile" : "review-cycle-form"}
      onSubmit={handleSubmit}
    >
      <header className="modal-head">
        <div className="modal-head-start">
          <Title size={5}>{title}</Title>
        </div>
        <div className="modal-head-end" id="review-cycle-buttons">
          <Button.Group hasAddons>
            {!isEditing && (
              <Button
                color="primary"
                form="review-cycle-form"
                size="small"
                type="button"
                onClick={() => setIsEditing(true)}
              >
                <span>Edit</span>
              </Button>
            )}

            {(!readOnly || isEditing) && (
              <Button
                color="primary"
                disabled={isDisabled}
                form="review-cycle-form"
                size="small"
                type="submit"
              >
                <span>Save</span>
              </Button>
            )}
            <Button
              id="cancel-btn"
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>

            {readOnly && (
              <Button
                id="danger-btn"
                size="small"
                type="button"
                onClick={handleConfirm}
              >
                <span>Delete</span>
              </Button>
            )}
          </Button.Group>
        </div>
      </header>
      <hr />
      <ReviewCycleForm
        inputs={inputs}
        readOnly={!isEditing}
        onChange={handleInputChange}
      />

      <ReviewSummaryList
        isTemplate
        handleClick={handleShowDetails}
        review={selectedReview}
      />
    </form>
  );
};

ReviewCycleModal.propTypes = {
  onComplete: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  reviewCycle: PropTypes.object,
  refetchReviewCycleList: PropTypes.func.isRequired,
};

ReviewCycleModal.defaultProps = {
  readOnly: false,
  reviewCycle: {},
};

export default ReviewCycleModal;
